
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { EstimationClient, TransportTypeConfigurationClient } from '@/services/Services';
import utils from '@/utils';
import * as OM from '@/Model';

@Options({})
export default class EditLayerTransportationProductionProcessModal extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() productionPhase: string;
    @Prop() layer: OM.LayerComposition;

    @Prop({
        default: false
    }) rawMaterialCallToAction: boolean;

    @Prop({
        default: () => new OM.TransportProductionPhase()
    }) editModel: OM.TransportProductionPhase;

    @Prop() currentTransportation: OM.TransportProductionPhase[];
    @Prop() allMaterials: OM.RawMaterialListVM[];
    @Prop() transportDistanceCalculation: OM.TransportDistanceCalculationVM[];
    @Prop() countries: OM.TextIdentifier[];
    @Prop() saveCallback: (layer: OM.EditLayerTransportationVM, rawMaterialList: OM.FabricComposition[]) => void;

    originCountryReadonly: boolean = false;
    hideMaterialsSelection: boolean = false;

    differentNextProductionSite: boolean = false;
    isTransportInternational: boolean = false;
    transportTypes: OM.TransportTypeConfiguration[] = [];
    from: string = "";
    to: string = "";
    editKmDenied: boolean = false;
    modelTransportType: OM.TransportTypeDistance = new OM.TransportTypeDistance();
    model: OM.EditLayerTransportationVM = new OM.EditLayerTransportationVM();

    loaded: boolean = false;

    created() {
        this.hideMaterialsSelection = utils.checkIfProductionPhaseIsNextFabric(this.productionPhase);

        this.model.identifier = this.estimationIdentifier;
        this.model.productionPhase = this.productionPhase;

        var proms = [];
        proms.push(TransportTypeConfigurationClient.getAllTransportTypes());

        if(this.rawMaterialCallToAction)
            proms.push(EstimationClient.getOriginCountryOfEstimation(this.estimationIdentifier));

        Promise.all(proms)
        .then(xs => {
            this.transportTypes = xs[0];
            if(this.layer)
                this.model.layerName = this.layer.layerName;

            if(this.editModel.rawMaterial.identifier) {
                this.model.fabricComposition.rawMaterial.identifier = this.editModel.rawMaterial.identifier;
                this.modelTransportType.from = this.countries.find(x => x.text == this.editModel.countryOfOrigin).identifier;
                this.modelTransportType.to = this.countries.find(x => x.text == this.editModel.countryOfDestination).identifier;

                if(this.modelTransportType.from != this.modelTransportType.to)
                    this.differentNextProductionSite = true;

                this.model.transportTypes = this.editModel.transportTypes;
            }
            else if(this.rawMaterialCallToAction)
                this.modelTransportType.from = xs[1];

            if(this.hideMaterialsSelection)
                this.rawMaterialUpdated(this.filteredRawMaterials[0].identifier);
            else
                this.rawMaterialUpdated("");
                
            this.loaded = true;
        })

    }

    get canAdd(){
        return this.modelTransportType.transportType && this.modelTransportType.distance && this.model.countryOfOrigin && this.model.countryOfDestination;
    }

    get filteredTransportTypesFromIsInternational() {
        var filteredList = this.transportTypes.filter(x => x.isInternational == this.isTransportInternational);
        return filteredList.map(x => x.transportType);
    }

    get filteredRawMaterials() {
        if(!this.layer)
            return this.allMaterials;

        if(this.editModel.rawMaterial.identifier)
            return this.layer.fabricCompositions.map(x => x.rawMaterial);

        return this.layer.fabricCompositions
            .filter(x => !this.currentTransportation.some(c => c.rawMaterial.identifier == x.rawMaterial.identifier))
            .map(x => x.rawMaterial);
    }

    rawMaterialUpdated(identifier: string){
        if(!identifier && this.layer)
            return;

        var layerName = "";

        if(this.layer) {
            layerName = this.layer.layerName;
            this.model.fabricComposition = this.layer.fabricCompositions.find( x => x.rawMaterial.identifier == identifier);

            if(this.modelTransportType.from) {
                if(!this.rawMaterialCallToAction && !this.layer.isPadding)
                    this.originCountryReadonly = true;
                    
                return;
            }
        }    
        else
            identifier = this.allMaterials[0].identifier;

        EstimationClient.getCountryOfDestinationFromPreviousStep(this.estimationIdentifier, identifier, this.productionPhase, layerName)
        .then(originCountryString => {
            if(originCountryString) {
                this.originCountryReadonly = true;
                this.modelTransportType.from = this.countries.find(x => x.text == originCountryString).identifier;
            }
        })
    }

    calcKmFromConfig(transportType: string) {
        this.from = this.countries.find(x => x.identifier == this.model.countryOfOrigin).text;
        this.to = this.countries.find(x => x.identifier == this.model.countryOfDestination).text;
        
        var existingConfiguration = this.transportDistanceCalculation.find(x => x.from.toLocaleLowerCase() == this.from.toLocaleLowerCase() &&
            x.to.toLocaleLowerCase() == this.to.toLocaleLowerCase() && x.transportType == transportType);

        if(!existingConfiguration)
            return null;

        return existingConfiguration.distanceKM;
    }
    checkKmsFromConfiguration() {
        this.model.countryOfOrigin = this.modelTransportType.from;

        if(!this.differentNextProductionSite)
            this.modelTransportType.to = this.modelTransportType.from;

        this.model.countryOfDestination = this.modelTransportType.to;

        var internationalTransports = this.transportTypes.filter(x => x.isInternational == true).map(y => y.transportType);
        var existTransport = this.model.transportTypes.filter(x => internationalTransports.indexOf(x.transportType) > -1);

        if(!this.model.countryOfOrigin || !this.model.countryOfDestination || (!this.modelTransportType.transportType && existTransport.length == 0))
            return;
        
        var distance = this.calcKmFromConfig(this.modelTransportType.transportType);

        if(!distance) {
            this.modelTransportType.distance = null;
            this.editKmDenied = false;
        }
        else {
            this.modelTransportType.distance = distance;
            this.editKmDenied = true;
        }

        if(existTransport.length > 0) {
            existTransport.forEach(element => {
                var indexOf = this.model.transportTypes.indexOf(element);
                if(indexOf > -1) {
                    var distanceKm = this.calcKmFromConfig(element.transportType);
                    
                    if(distanceKm)
                        this.model.transportTypes[indexOf].distance = distanceKm;
                    else
                        this.model.transportTypes.splice(indexOf, 1);
                }
            });
        }
    }

    addTransportType() {
        this.modelTransportType.from = this.from;
        this.modelTransportType.to = this.to;
        this.model.transportTypes.push(this.modelTransportType);

        this.modelTransportType = new OM.TransportTypeDistance();
        this.modelTransportType.from = this.model.countryOfOrigin;
        this.modelTransportType.to = this.model.countryOfDestination;

        this.model.countryOfOrigin = "";
        this.model.countryOfDestination = "";
    }
    removeTransportType(index: number) {
        this.model.transportTypes.splice(index, 1);
    }

    get isDisabled() {
        if((this.hideMaterialsSelection || this.layer == null) && !this.differentNextProductionSite)
            return !this.modelTransportType.from;
        else if((!this.hideMaterialsSelection && this.layer != null) && !this.differentNextProductionSite)
            return !this.modelTransportType.from || !this.model.fabricComposition.rawMaterial.identifier;
        else if (this.differentNextProductionSite)
            return !this.modelTransportType.from || this.model.transportTypes.length == 0;
    }

    save() {
        if(!this.differentNextProductionSite)
            this.modelTransportType.to = this.modelTransportType.from;

        this.model.countryOfOrigin = this.countries.find(x => x.identifier == this.modelTransportType.from).text;
        this.model.countryOfDestination = this.countries.find(x => x.identifier == this.modelTransportType.to).text;

        var rawMaterialList: OM.FabricComposition[] = [];
        if(this.hideMaterialsSelection) {
            this.filteredRawMaterials.forEach(element => {
                if(this.layer) {
                    var fabric = this.layer.fabricCompositions.find( x => x.rawMaterial.identifier == element.identifier);
                    rawMaterialList.push(fabric);
                }
            });
            this.saveCallback(this.model, rawMaterialList);
        }
        else {
            rawMaterialList.push(this.model.fabricComposition);
            this.saveCallback(this.model, rawMaterialList);
        }

    }
}
